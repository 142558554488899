exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articulos-js": () => import("./../../../src/pages/articulos.js" /* webpackChunkName: "component---src-pages-articulos-js" */),
  "component---src-pages-consejos-js": () => import("./../../../src/pages/consejos.js" /* webpackChunkName: "component---src-pages-consejos-js" */),
  "component---src-pages-email-confirmation-js": () => import("./../../../src/pages/email-confirmation.js" /* webpackChunkName: "component---src-pages-email-confirmation-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nuestros-expertos-js": () => import("./../../../src/pages/nuestros-expertos.js" /* webpackChunkName: "component---src-pages-nuestros-expertos-js" */),
  "component---src-pages-perfil-js": () => import("./../../../src/pages/perfil.js" /* webpackChunkName: "component---src-pages-perfil-js" */),
  "component---src-pages-recetas-js": () => import("./../../../src/pages/recetas.js" /* webpackChunkName: "component---src-pages-recetas-js" */),
  "component---src-templates-advise-template-js": () => import("./../../../src/templates/advise-template.js" /* webpackChunkName: "component---src-templates-advise-template-js" */),
  "component---src-templates-author-template-js": () => import("./../../../src/templates/author-template.js" /* webpackChunkName: "component---src-templates-author-template-js" */),
  "component---src-templates-blogs-templates-js": () => import("./../../../src/templates/blogs-templates.js" /* webpackChunkName: "component---src-templates-blogs-templates-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-en-js": () => import("./../../../src/templates/post-template-en.js" /* webpackChunkName: "component---src-templates-post-template-en-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-recipe-template-js": () => import("./../../../src/templates/recipe-template.js" /* webpackChunkName: "component---src-templates-recipe-template-js" */),
  "component---src-templates-recipes-js": () => import("./../../../src/templates/recipes.js" /* webpackChunkName: "component---src-templates-recipes-js" */)
}

